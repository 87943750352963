var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog-inner",staticStyle:{"padding-top":"0"}},[_c('el-form',{ref:"form",staticClass:"form-col2",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"120px","show-message":true}},[_c('div',{staticClass:"form-group-title",staticStyle:{"margin-top":"5px"}},[_c('h2',[_vm._v("组织信息")])]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"所属组织"}},[_vm._v(" "+_vm._s(_vm.parentOrg.name)+" ")])],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"组织名称","prop":"name"}},[_c('el-input',{attrs:{"type":"text","size":"small","maxlength":"20"},on:{"blur":function($event){return _vm.fieldTrim(_vm.form, 'name')}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"类型"}},[_vm._v(" "+_vm._s(_vm.orgType.name)+" ")])],1)],1),(_vm.form.type === 2)?[_c('div',{staticClass:"form-group-title"},[_c('h2',[_vm._v("项目信息")])]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"工程类别","prop":"projectType","rules":[
                            {required: true, message: '工程类别不能为空', trigger: 'change'}
                        ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.form.projectType),callback:function ($$v) {_vm.$set(_vm.form, "projectType", $$v)},expression:"form.projectType"}},_vm._l((_vm.projectTypeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"项目状态","prop":"projectStatus","rules":[
                            {required: true, message: '项目状态不能为空', trigger: 'change'}
                        ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.form.projectStatus),callback:function ($$v) {_vm.$set(_vm.form, "projectStatus", $$v)},expression:"form.projectStatus"}},_vm._l((_vm.projectStatusList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})}),1)],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"开工时间","prop":"startTime","rules":[
                            {required: true, message: '开工时间不能为空', trigger: 'change'},
                            {validator: this.checkStartTime, trigger: 'change'} ]}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value-format":"yyyy-MM-dd","placeholder":"选择日期","size":"small"},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"竣工时间","prop":"endTime","rules":[
                            {required: true, message: '竣工时间不能为空', trigger: 'change'},
                            {validator: this.checkEndTime, trigger: 'change'} ]}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value-format":"yyyy-MM-dd","placeholder":"选择日期","size":"small"},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"项目地址","prop":"projectAddress","rules":[
                            {required: true, message: '项目地址不能为空', trigger: 'change'}
                        ]}},[_c('el-input',{attrs:{"type":"text","size":"small","maxlength":"200"},on:{"blur":function($event){return _vm.fieldTrim(_vm.form, 'projectAddress')}},model:{value:(_vm.form.projectAddress),callback:function ($$v) {_vm.$set(_vm.form, "projectAddress", $$v)},expression:"form.projectAddress"}})],1)],1)],1),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"DingDingLink","prop":"dingDing","rules":[
                            {required: false, validator: _vm.checkUrl, trigger: 'change'}
                        ]}},[_c('el-input',{attrs:{"type":"text","size":"small","maxlength":"500"},on:{"blur":function($event){return _vm.fieldTrim(_vm.form, 'dingDing')}},model:{value:(_vm.form.dingDing),callback:function ($$v) {_vm.$set(_vm.form, "dingDing", $$v)},expression:"form.dingDing"}})],1)],1)],1)]:_vm._e(),_c('el-row',{staticClass:"submit-row",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting,"size":"small"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")]),_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.resetForm}},[_vm._v("重置")])],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }