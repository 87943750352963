<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px" :show-message="true" class="form-col2">
            <div class="form-group-title" style="margin-top:5px;"><h2>组织信息</h2></div>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item
                        label="所属组织"
                        prop="pid">
                        <SelectTree
                            ref="selectTree"
                            placeholder="请选择"
                            v-model="form.pid"
                            :data="orgTree"
                            :treeProps="{ children: 'children', label: 'name' }"
                            default-expand-all
                            filterable
                            clearable
                            style="width:100%;"
                            size="small">
                        </SelectTree>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="组织名称" prop="name">
                        <el-input type="text" v-model="form.name" size="small" maxlength="20" @blur="fieldTrim(form, 'name')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="类型" prop="type">
                        <el-select
                            v-model="form.type"
                            placeholder="请选择"
                            size="small"
                            style="width:100%;">
                            <el-option
                                v-for="item in orgTypeList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <template v-if="form.type === 2">
                <div class="form-group-title"><h2>项目信息</h2></div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item
                            label="工程类别"
                            prop="projectType"
                            :rules="[
                                {required: true, message: '工程类别不能为空', trigger: 'change'}
                            ]">
                            <el-select
                                v-model="form.projectType"
                                placeholder="请选择"
                                size="small"
                                style="width:100%;">
                                <el-option
                                    v-for="item in projectTypeList"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="项目状态"
                            prop="projectStatus"
                            :rules="[
                                {required: true, message: '项目状态不能为空', trigger: 'change'}
                            ]">
                            <el-select
                                v-model="form.projectStatus"
                                placeholder="请选择"
                                size="small"
                                style="width:100%;">
                                <el-option
                                    v-for="item in projectStatusList"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item
                            label="开工时间"
                            prop="startTime"
                            :rules="[
                                {required: true, message: '开工时间不能为空', trigger: 'change'},
                                {validator: this.checkStartTime, trigger: 'change'},
                            ]">
                            <el-date-picker
                                v-model="form.startTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                size="small"
                                style="width:100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="竣工时间"
                            prop="endTime"
                            :rules="[
                                {required: true, message: '竣工时间不能为空', trigger: 'change'},
                                {validator: this.checkEndTime, trigger: 'change'},
                            ]">
                            <el-date-picker
                                v-model="form.endTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                size="small"
                                style="width:100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item
                            label="项目地址"
                            prop="projectAddress"
                            :rules="[
                                {required: true, message: '项目地址不能为空', trigger: 'change'}
                            ]">
                            <el-input type="text" v-model="form.projectAddress" size="small" maxlength="200" @blur="fieldTrim(form, 'projectAddress')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item
                            label="DingDingLink"
                            prop="dingDing"
                            :rules="[
                                {validator: checkUrl, trigger: 'change'}
                            ]">
                            <el-input type="text" v-model="form.dingDing" size="small" maxlength="500" @blur="fieldTrim(form, 'dingDing')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import SelectTree from "@/components/SelectTree"

export default {
    name: 'OrgAdd',
    components: {
        SelectTree
    },
    props: {
        orgTree: {
            type: Array,
            default () {
                return [];
            }
        },
        params: {
            type: Object,
            default () {
                return {};
            }
        }
    },
    data(){
        return {
            orgTypeList: [
                {
                    value: 1,
                    name: '组织',
                },
                {
                    value: 2,
                    name: '项目',
                },
            ],
            projectTypeList: [
                {
                    value: 1,
                    name: '房建工程',
                },
                {
                    value: 2,
                    name: '市政工程',
                },
                {
                    value: 3,
                    name: '安装工程',
                },
                {
                    value: 4,
                    name: '装饰工程',
                },
                {
                    value: 5,
                    name: '公路工程',
                },
                {
                    value: 6,
                    name: '建安工程',
                },
                {
                    value: 7,
                    name: '地铁工程',
                },
            ],
            projectStatusList: [
                {
                    value: 0,
                    name: '未开工',
                },
                {
                    value: 1,
                    name: '在建',
                },
                {
                    value: 2,
                    name: '停工',
                },
                {
                    value: 9,
                    name: '完工',
                }
            ],
            form: {
                pid: '',
                name: '',
                type: '',
                projectType: '',
                projectAddress: '',
                projectStatus: '',
                startTime: '',
                endTime: '',
                dingDing: '',
            },
            rules: {
                pid: [{required: true, message: '所属组织不能为空', trigger: 'change'}],
                name: [{required: true, message: '组织名称不能为空', trigger: 'blur'}],
                type: [{required: true, message: '类型不能为空', trigger: 'change'}],
            },
            submitting: false
        }
    },
    created() {
        this.$nextTick(() => {  this.form.pid = this.params.orgId})
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        ...common,
        ...verify,
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post('v1/org/add', this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('添加成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.tableLoading = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleDeptChange(value) {
            let dept = this.getListItem(this.deptList, 'id', value);
            if (!dept) {
                this.postList = [];
                return;
            }

            this.postList = dept.postList;
        },
        checkStartTime (rule, value, callback) {
            let arr = value.split('-');
            let sd = new Date(arr[0],arr[1],arr[2],0,0,0);

            if (!this.form.endTime || this.form.endTime == '') {
                callback();
                return;
            }

            arr = this.form.endTime.split('-');
            let ed = new Date(arr[0],arr[1],arr[2],0,0,0);

            if (sd.getTime() > ed.getTime()) {
                callback(new Error('开工时间不能晚于竣工时间'));
            } else {
                callback();
            }

            this.checkEndTimeId = setTimeout(() => {
                this.$refs.form.validateField('endTime');
                clearTimeout(this.checkStartTimeId);
            }, 10);
        },
        checkEndTime (rule, value, callback) {
            let arr = value.split('-');
            let ed = new Date(arr[0],arr[1],arr[2],0,0,0);

            if (!this.form.startTime || this.form.startTime == '') {
                callback();
                return;
            }

            arr = this.form.startTime.split('-');
            let sd = new Date(arr[0],arr[1],arr[2],0,0,0);

            if (sd.getTime() > ed.getTime()) {
                callback(new Error('竣工时间不能早于开工时间'));
            } else {
                callback();
            }

            this.checkStartTimeId = setTimeout(() => {
                this.$refs.form.validateField('startTime');
                clearTimeout(this.checkEndTimeId);
            }, 10);
        },
    }
};
</script>

<style scoped>
</style>
