<template>
    <div class="frame-content">
        <div class="layout-left" style="width:260px;">
            <div class="filter-tree">
                <el-input
                    placeholder="搜索"
                    v-model="filterTreeText"
                    size="small">
                </el-input>
            </div>
            <div class="left-box-wrap">
                <Scrollbar style="height:100%;">
                    <div class="left-box">
                        <el-tree 
                            ref="leftTree"
                            :expand-on-click-node="false"
                            :data="treeData" 
                            :filter-node-method="filterNode"
                            default-expand-all
                            node-key="id"
                            :props="{ children: 'children', label: 'name' }" 
                            @node-click="handleNodeClick">
                        </el-tree>
                    </div>
                </Scrollbar>
            </div>
        </div>
        <div class="frame-content-wrap layout-right" style="margin-left:260px;">
            <div class="filter">
                <div class="inline">
                    <el-input v-model="filters.relName" placeholder="姓名" size="small"></el-input>
                </div>
                <div class="inline ml-8">
                    <el-input v-model="filters.tel" placeholder="手机号" size="small"></el-input>
                </div>
                <div class="inline date-picker date-picker-small ml-8">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline">
                        <el-button type="primary" size="small" @click="handleAddOrg">添加组织</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="warning" size="small" @click="handleEditOrg">修改组织</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteOrgAll">删除组织</el-button>
                    </div>
                    <div class="inline ml-15">
                        <el-button type="primary" size="small" @click="handleAdd">新增人员</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除人员</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="success" size="small" @click="handleDisable(0)">启用</el-button>
                    </div>
                    <div class="inline ml-8">
                        <el-button type="warning" size="small" @click="handleDisable(1)">禁用</el-button>
                    </div>
                </div>
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="60">
                            <template slot-scope="scope">
                                <span>{{ (pageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="orgName"
                            label="所属组织"
                            align="left"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="userNo"
                            label="工号"
                            align="center"
                            width="80">
                        </el-table-column>
                        <el-table-column
                            prop="userName"
                            label="姓名">
                        </el-table-column>
                        <el-table-column
                            prop="deptName"
                            label="部门">
                        </el-table-column>
                        <el-table-column
                            prop="positionName"
                            label="岗位">
                        </el-table-column>
                        <el-table-column
                            prop="tel"
                            align="left"
                            label="联系电话"
                            width="130">
                        </el-table-column>
                        <el-table-column
                            prop="enabled"
                            label="状态"
                            align="center"
                            width="100">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.enabled == 0" type="primary" disable-transitions>启用</el-tag>
                                <el-tag v-else type="danger" disable-transitions>禁用</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="lastLoginTime"
                            label="最近登录时间"
                            align="center"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="created"
                            label="创建时间"
                            align="center"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="120">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="warning"
                                    @click="handleEdit(scope.$index, scope.row)">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>
        </div>

        <el-dialog
            title="添加组织"
            v-if="addOrgDialog.create"
            :visible.sync="addOrgDialog.visible"
            :close-on-click-modal="false"
            @closed="handleAddOrgClosed"
            class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
            <OrgAdd :org-tree="treeData" :params="addOrgDialog.params" @done="handleAddOrgDone"></OrgAdd>
        </el-dialog>
        
        <el-dialog
            title="修改组织"
            v-if="editOrgDialog.create"
            :visible.sync="editOrgDialog.visible"
            :close-on-click-modal="false"
            @closed="handleEditOrgClosed"
            class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
            <OrgEdit :org-tree="treeData" :params="editOrgDialog.params" @done="handleEditOrgDone"></OrgEdit>
        </el-dialog>

         <el-dialog
            title="新增人员"
            v-if="addDialog.create"
            :visible.sync="addDialog.visible"
            :close-on-click-modal="false"
            @closed="handleAddClosed"
            class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
            <UserOrgAdd @done="handleAddDone"></UserOrgAdd>
        </el-dialog>
        
        <el-dialog
            title="修改人员"
            v-if="editDialog.create"
            :visible.sync="editDialog.visible"
            :close-on-click-modal="false"
            @closed="handleEditClosed"
            class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
            <UserOrgEdit :params="editDialog.params" @done="handleEditDone"></UserOrgEdit>
        </el-dialog>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import Scrollbar from '@/components/Scrollbar'
import OrgAdd from './OrgAdd'
import OrgEdit from './OrgEdit'
import UserOrgAdd from './UserOrgAdd'
import UserOrgEdit from './UserOrgEdit'

export default {
    name: 'SystemOrg',
    components: {
        Scrollbar,
        OrgAdd,
        OrgEdit,
        UserOrgAdd,
        UserOrgEdit
    },
    data() {
        return {
            treeData: [],

            nodeId: '',
            nodeData: {},
            filterTreeText: '',

            filters: {
                name: '',
                tel: '',
                startTime: '',
                endTime: '',
            },

            dateRange: [],
            
            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            tableLoading: false,

            addOrgDialog: {
                create: false,
                visible: false,
                params: {},
            },

            editOrgDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addDialog: {
                create: false,
                visible: false,
                params: {},
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTree();
    },
    watch: {
        filterTreeText(val) {
            this.$refs.leftTree.filter(val);
        },
        ['dateRange'] (val) {
            if (!val || val.length < 1) {
                this.filters.startTime = '';
                this.filters.endTime = '';
                return;
            }
            this.filters.startTime = val[0];
            this.filters.endTime = val[1];
        }
    },
    methods: {
        ...common,
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        loadTree() {
            let params = { tenantId: 1 };

            http.get('v1/org/orgTree', {
                params: params
            }).then(req => {
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    this.treeData = req.data.data;
                    this.$nextTick(() => {
                        if (this.treeData && this.treeData.length > 0 && this.$refs.leftTree) {
                            if (this.nodeId != '') {
                                let node = this.$refs.leftTree.getNode(this.nodeId);
                                if (!node) {
                                    this.nodeId = this.treeData[0].id;
                                    this.nodeData = this.treeData[0];
                                }
                            } else {
                                this.nodeId = this.treeData[0].id;
                                this.nodeData = this.treeData[0];
                            }
                            
                            this.$refs.leftTree.setCurrentKey(this.nodeId);

                            this.loadTable();
                        }
                    });
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadTable() {
            let {
                name,
                tel,
                startTime,
                endTime,
            } = this.filters;

            let params = {};

            if (this.nodeId != '') {
                params.orgId = this.nodeId;
            } else {
                return;
            }

            if (name != '') {
                params.name = name;
            }

            if (tel != '') {
                params.tel = tel;
            }

            if (startTime != '' && endTime != '') {
                params.startTime = startTime;
                params.endTime = endTime;
            }

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/user/listPage', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data.records;
                    data.forEach(item => {
                        item.created = item.created.substr(0, 16);
                    });
                    this.tableData = data;
                    this.totalNum = req.data.data.total;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        // handleNodeClick(data, node, vuecomponent) {
        handleNodeClick(data) {
            this.nodeId = data.id;
            this.nodeData = data;
            this.loadTable();
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },
        handleDetail(index, row) {
            this.detailDialog = { visible: true, params: row };
        },

        // 添加组织
        handleAddOrg() {
            this.addOrgDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addOrgDialog = { create: true, visible: true,params: { orgId: this.nodeId } };
            });
        },
        handleAddOrgDone() {
            this.addOrgDialog = { create: true, visible: false };
            this.loadTree();
        },
        handleAddOrgClosed() {
            this.addOrgDialog = { create: false, visible: false };
        },
        
        // 编辑组织
        handleEditOrg() {
            if (this.nodeId == '') {
                this.showError('请选择右侧组织！');
                return;
            }

            if (this.nodeData.level == 0) {
                this.showError('不能编辑顶级组织！');
                return;
            }

            this.editOrgDialog = { create: true, visible: true, params: { orgId: this.nodeId } };
            this.$nextTick(() => {
                this.editOrgDialog = { create: true, visible: true, params: { orgId: this.nodeId } };
            });
        },
        handleEditOrgDone() {
            this.editOrgDialog = { create: true, visible: false };
            this.loadTree();
        },
        handleEditOrgClosed() {
            this.editOrgDialog = { create: false, visible: false };
        },

        // 删除组织
        handleDeleteOrgAll() {
            if (this.nodeId == '') {
                this.showError('请选择右侧组织！');
            } else {
                if (this.nodeData.level == 0) {
                    this.showError('不能删除顶级组织！');
                    return;
                }
                this.confirm('确认删除所选组织？', function () {
                    http.post('v1/org/del', { id: this.nodeId }).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.nodeId = '';
                            this.nodeData = {};
                            this.loadTree();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },

        handleAdd() {
            this.addDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true };
            });
        },
        handleAddDone() {
            this.addDialog = { create: true, visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = { create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false };
        },

        handleDisable(status) {
            let text;
            if (status == 1) {
                text = '禁用'
            } else if (status == 0) {
                text = '启用'
            } else {
                this.showError('未知状态值！');
                return;
            }

            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要'+text+'的信息！');
            } else {
                this.confirm('确认'+text+'所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/user/enable', { ids: idarr, enabled: status, orgId: this.nodeId }).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess(text+'成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },

        handleDeleteAll() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的信息！');
            } else {
                this.confirm('确认删除所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/user/del', { ids: idarr }).then(req => {
                        if (this.httpThen(req)) {
                            return;
                        }
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },
    }
}
</script>

<style scoped>
</style>