import { render, staticRenderFns } from "./OrgAdd.vue?vue&type=template&id=4e8b562c&scoped=true&"
import script from "./OrgAdd.vue?vue&type=script&lang=js&"
export * from "./OrgAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8b562c",
  null
  
)

export default component.exports